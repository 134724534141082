<template>
    <nav role="navigation">
        <router-link v-for="(link, id) in structure" :key="id" :to="link.route">
            <i :class="link.icon"></i>

            <small>
                {{ link.name }}
            </small>
        </router-link>

        <router-link v-if="isAdmin" :to="{ name: 'ClientsPage' }">
            <i class="fa-solid fa-users"></i>

            <small>
                Clients
            </small>
        </router-link>

        <a href="#" class="cart" :class="{ disabled: cart.length === 0}" @click.prevent="showCart">
            <i class="fa-regular fa-basket-shopping"></i>

            <small :data-size="cart.length">
                Cart
            </small>
        </a>
    </nav>

    <a href="#" class="logout" @click.prevent="$emit('logout')">
        <i class="fa-solid fa-arrow-right-from-bracket fa-flip-horizontal"></i>

        <small>
            Log out
        </small>
    </a>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    computed: {
        ...mapGetters([
            'cart',
            'isAdmin',
            'structure',
        ]),
    },


    methods: {
        showCart()
        {
            if (vm.cart.length > 0)
            {
                vm.$emit('showCart');
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>