<template>
    <div v-if="initialized">

        <teleport to="header">
            <app-header v-if="isLoggedIn" @logout="logoutUser" @showCart="showCart" />
        </teleport>


        <transition name="fade" mode="out-in">
            <div class="loader" v-if="processing">
                <i class="fa-sharp fa-light fa-spinner-third fa-spin fa-4x"></i>
            </div>
        </transition>

        <transition name="fade" mode="out-in">
            <aside class="note" v-if="discountMessage" :key="discountMessage" v-html="discountMessage" />
        </transition>


        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component v-if="isLoggedIn" :is="Component" :class="isMobile ? 'mobile' : 'desktop'" :key="routePath"
                    @logout="logoutUser" @processing="setProcessing" @showCart="showCart" @updateImage="updateImage">

                    <template v-slot:footer>
                        <app-footer v-if="isLoggedIn" />
                    </template>
                </component>

                <main v-else key="loggedout">
                    <section class="login">
                        <login-form />
                    </section>
                </main>

            </transition>
        </router-view>



        <dialog v-if="toUpdate" ref="modal">
            <a href="#" class="close" @click.prevent="closeModal">
                <i class="fa-solid fa-times"></i>
            </a>

            <image-form :toUpdate="toUpdate" @close="closeModal" />
        </dialog>

        <dialog v-if="isLoggedIn" ref="cart">
            <a href="#" class="close" @click.prevent="showCartModal = false">
                <i class="fa-solid fa-times"></i>
            </a>

            <cart-preview @processing="setProcessing" />
        </dialog>

    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';


import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import CartPreview from '@/components/CartPreview.vue';
import ImageForm from '@/components/ImageForm.vue';
import LoginForm from '@/components/LoginForm.vue';


var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
        CartPreview,
        ImageForm,
        LoginForm,
	},

	
	data()
	{
		return {
            initialized: false,
            isMobile: null,
            processing: false,
            smoother: null,
            toUpdate: null,
            showCartModal: false,
		};
	},


	computed: {
		...mapGetters([
			'base',
            'discountMessage',
			'duration',
			'isLoggedIn',
			'siteName',
            'structure',
		]),


		routePath()
		{
			let p = vm.$route.fullPath;

			return p;
		},
	},


	methods: {
		...mapActions([
			'_initialize',
            'getImages',
            'logout',
        ]),


        closeModal()
        {
            let modal = vm.$refs.modal;
            
            modal.removeEventListener('click', vm.modalEventListener);
            modal.close();

            vm.$nextTick(() => {
                vm.toUpdate = null;
            })
        },


        detectTouch()
        {
            try {
                document.createEvent('TouchEvent');
                vm.isMobile = true;
            }
            catch (e)
            {
                vm.isMobile = false;
            }
        },


		logoutUser()
		{
			// Do a minor delay for the general "onClick" listener on the DOM
			setTimeout(() => {
				vm.logout();
				vm.$router.push({ name: 'HomePage' })
			}, 13);
		},


        modalEventListener(event)
        {
            let el = event.target;

            let rect = el.getBoundingClientRect(),
                isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);

            if (!isInDialog)
            {
                // Unset all possible modal requirements
                vm.showCartModal = false;
                vm.closeModal();
            }
        },


        setProcessing(value)
        {
            vm.processing = value;
        },


        showCart()
        {
            vm.showCartModal = true;
        },


        updateImage(img)
        {
            vm.toUpdate = img;
        },
	},


	created()
	{
        vm = this;

        // Detect whether or not this is a touch device
        vm.detectTouch();

        let login;
        if (localStorage.getItem('axpmUser')) login = JSON.parse(atob(localStorage.getItem('axpmUser')));

        vm._initialize(login).then(() => {
            if (vm.isLoggedIn)
            {
                vm.getImages()
                .then(() => {
                    vm.initialized = true;
                })
            }
            else
            {
                vm.initialized = true;
            }
        })
	},


    watch: {
        showCartModal(to)
        {
            let modal = vm.$refs.cart;

            if (to)
            {
                modal.showModal();

                vm.$nextTick(() => {
                    modal.addEventListener('click', vm.modalEventListener)
                })
            }
            else
            {
                modal.removeEventListener('click', vm.modalEventListener);

                vm.$nextTick(() => {
                    modal.close();
                })
            }
        },


        toUpdate(to)
        {
            vm.$nextTick(() => {
                let modal = vm.$refs.modal;
    
                if (to)
                {
                    modal.showModal();

                    vm.$nextTick(() => {
                        modal.addEventListener('click', vm.modalEventListener)
                    })
                }
                // There is no else, because that is covered in closeModal()
            })
        },
    },
};
</script>


<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/scss/app.scss';
</style>
