<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section>
                    <h1>
                        My information
                    </h1>

                    <ul class="user">
                        <li>
                            <strong>
                                Name:
                            </strong>

                            <span>
                                {{ user.name }}
                            </span>
                        </li>

                        <li>
                            <strong>
                                Email:
                            </strong>

                            <span>
                                {{ user.email }}
                            </span>
                        </li>

                        <li v-if="userTier">
                            <strong>
                                Tier:
                            </strong>

                            <span>
                                {{ userTier.name }}
                            </span>
                        </li>

                        <li>
                            <strong>
                                License fee
                            </strong>

                            <span>
                                {{ asMoney(userTier.license_fee) }} (per image)
                            </span>
                        </li>

                        <li class="logout">
                            <a href="#" class="button" @click.prevent="$emit('logout')">
                                Log out
                            </a>
                        </li>
                    </ul>

                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import scrollFX from '@/mixins/scrollFX';

import useCart from '@/hooks/cart.js';

// var vm;

export default {
    mixins: [
        scrollFX,
    ],


    computed: {
        ...mapGetters([
            'user',
            'userTier',
        ]),
    },


	setup()
	{
		const { asMoney } = useCart();


		return {
            asMoney,
		};
	},


    // created()
    // {
    //     vm = this;
    // },
}
</script>