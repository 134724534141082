<template>
    <form class="admin-form" enctype="multipart/form-data">
        <h2>
            {{ title }}
        </h2>

        <div v-if="!isUpdate" class="dragdrop-area" :class="isDragging ? 'dragging' : ''" @dragover.prevent="onDragOver"
            @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">
            <input type="file" ref="filesSelect" id="files" multiple @change="onFileSelect" />

            <label for="files"><strong>Choose a file</strong> or drag it here.</label>
        </div>

        <div v-else>

            <ul>
                <li>
                    <input type="file" ref="filesSelect" id="image" @change="onFileSelectUpdate" />
                </li>

                <li>
                    <label for="update-price">
                        Price
                    </label>
                    <input type="number" id="update-price" v-model="image.price" />
                </li>

                <li>
                    <button @click.prevent="updateImage">
                        Update
                    </button>
                </li>
            </ul>
        </div>


        <h2 v-if="images.length > 0">
            Ready to upload
        </h2>

        <ul v-if="images.length > 0" class="upload-queue">
            <li v-for="(img, id) in images" :key="id">
                <img :src="img.url" :alt="img.id" />

                <strong>
                    {{ img.id }}
                </strong>

                <input type="number" :value="img.price" :data-index="id" step="5" @input="updateImagePrice" />

                <span class="delete" @click="removeImage(id)">
                    &times;
                </span>
            </li>

            <li v-if="images.length > 0">
                <button @click.prevent="uploadImages">
                    Upload
                </button>
            </li>
        </ul>
    </form>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

var vm;

export default {
    props: {
        toUpdate: {
            type: [Boolean, Object],
            default: false,
        },
    },


    data()
    {
        return {
            files: [],
            image: {},
            images: [],
            isDragging: false,
            updateFiles: [],
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'user',
        ]),


        isUpdate()
        {
            return vm.toUpdate !== false;
        },
        

        title()
        {
            return vm.isUpdate ? 'Update ' + vm.toUpdate.id : 'Add images';
        },
    },
    

    methods: {
        ...mapActions([
            'updateImages',
            'updateUser',
        ]),


        async updateImage()
        {
            vm.$emit('processing', true);

            try {
                let formData = new FormData();

                for (let i = 0; i < vm.updateFiles.length; i++)
                {
                    let f = vm.updateFiles[i];

                    formData.append('files[' + i + ']', f);
                }

                // Add other updatable info
                formData.append('id', vm.image.id);
                formData.append('price', vm.image.price);

                const response = await axios.post(`api/image/${vm.image.id}/replace`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Remove successful uploads from the queue
                if (response.data.success)
                {
                    vm.$emit('close');
                }
                else
                {
                    alert('Failed to update the image.');
                }

                // // Update the images and the user objects
                vm.updateImages(response.data.images);
                vm.updateUser(response.data.user);
                vm.$emit('processing', false);
            } catch (error) {
                console.log(error);
            }
        },


        async uploadImages()
        {
            vm.$emit('processing', true);

            try {
                let formData = new FormData();

                for (let i = 0; i < vm.files.length; i++)
                {
                    let f = vm.files[i];

                    formData.append('files[' + i + ']', f);
                }

                for (let j = 0; j < vm.images.length; j++)
                {
                    let img = vm.images[j];

                    formData.append('images[' + j + ']', JSON.stringify(img));
                }

                const response = await axios.post('api/image/create', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                let recap = [];

                if (response.data.exists.length > 0) recap.push(`- Images ${response.data.exists.join(', ')} existed.`);
                if (response.data.failed.length > 0) recap.push(`- Images ${response.data.failed.join(', ')} failed.`);

                // Remove successful uploads from the queue
                if (response.data.success.length > 0)
                {
                    // Loop backwards, so it's always the last one that gets removed first
                    // If you don't, not the entire list will get cleared.
                    for (let i = vm.images.length - 1; i >= 0; i--)
                    {
                        if (response.data.success.indexOf(vm.images[i].id) >= 0) vm.removeImage(i);
                    }
                }

                // Update the images and the user objects
                vm.updateImages(response.data.images);
                vm.updateUser(response.data.user);
                vm.$emit('processing', false);

                // If there were failed uploads, report them here
                if (recap.length > 0) alert(recap.join('\n'));
            } catch (error) {
                console.log(error);
            }
        },


        addImages(files)
        {
            if (files.length === 0) return;

            vm.files = files;

            for (let i = 0; i < files.length; i++)
            {
                let f = files[i],
                    id= vm.getImageId(f.name);

                if (f.type.split('/')[0] != 'image') continue;

                if (!vm.images.some((el) => el.id === id))
                {
                    vm.images.push({
                        id: id,
                        name: f.name,
                        price: 30,
                        url: URL.createObjectURL(f),
                    })
                }
            }
        },


        getImageId(name)
        {
            return [vm.user.prefix, name.replace('_', '').split('.')[0]].join('-');
        },
        
        
        onDragLeave()
        {
            vm.isDragging = false;
        },

        
        onDragOver(e)
        {
            vm.isDragging = true;
            e.dataTransfer.type = 'copy';
        },


        onDrop(e)
        {
            vm.addImages(e.dataTransfer.files);
        },

        
        onFileSelect(e)
        {
            vm.addImages(e.target.files);
        },

        
        onFileSelectUpdate(e)
        {
            vm.updateFiles = e.target.files;
        },


        removeImage(id)
        {
            vm.images.splice(id, 1);
        },


        updateImagePrice(e)
        {
            let el = e.target;
            
            vm.images[el.dataset.index].price = el.value;
        },
    },


    created()
    {
        vm = this;

        if (vm.toUpdate)
        {
            let img = JSON.parse(JSON.stringify(vm.toUpdate));
            img.price = parseFloat(img.price);
            vm.image = img;
        }
    },
}
</script>