<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section v-if="user.fk_tier != 1">
                    <h1>
                        Access denied.
                    </h1>
                </section>

                <section class="sidebar" v-else>
                    <aside>
                        <client-form @processing="process" />
                    </aside>

                    <div class="main">
                        <h2>
                            All artists
                        </h2>
    
                        <ul class="clients">
                            <li v-if="clients === null">
                                <i class="fa-sharp fa-light fa-spin fa-spinner-third"></i>
                            </li>
                            <li v-else-if="clients.length === 0">
                                <span class="full">
                                    There are no clients yet.
                                </span>
                            </li>
                            <li v-for="c in clients" :key="c.id">
                                <span>
                                    #{{ c.id }}
                                </span>

                                <strong>
                                    {{ c.name }}
                                </strong>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import scrollFX from '@/mixins/scrollFX';

import ClientForm from '@/components/ClientForm';

var vm;

export default {
    components: {
        ClientForm,
    },

    
    mixins: [
        scrollFX,
    ],


    computed: {
        ...mapGetters([
            'clients',
            'user',
        ]),
    },


    methods: {
        ...mapActions([
            'getClients',
        ]),


        process(value)
        {
            vm.$emit('processing', value);
        },
    },


    created()
    {
        vm = this;

        vm.getClients();
    },
}
</script>