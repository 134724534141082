<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section>
                    <h1>
                        Work in progress...
                    </h1>
                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import scrollFX from '@/mixins/scrollFX';

export default {
    mixins: [
        scrollFX,
    ],
}
</script>