<template>
    <form class="admin-form" ref="form">
        <h2>
            Add a client
        </h2>

        <ul class="unstyled-list">
            <li>
                <input type="text" name="name" placeholder="Name..." v-model="client.name" />
            </li>
            <li>
                <input type="email" name="email" placeholder="Email..." v-model="client.email" />
            </li>
            <li>
                <select name="fk_tier" v-model="client.fk_tier">
                    <option v-for="t in tiers" :key="t.id" :value="t.id">
                        {{ t.name }}
                    </option>
                </select>
            </li>
            <li>
                <input type="text" v-model="vatNumber" placeholder="VAT ID (if applicable)" />
            </li>
            <li>
                <select v-model="vatRate">
                    <option v-for="r in vatRates" :key="r.rate" :value="r.rate">
                        {{ r.text }}
                    </option>
                </select>
            </li>
            <li>
                <button @click.prevent="addUser">
                    Add user
                </button>
            </li>
        </ul>
    </form>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

var vm;

export default {
    data()
    {
        return {
            client: {
                name: '',
                email: '',
                fk_tier: 2,
            },
            default: {
                name: '',
                email: '',
                fk_tier: 2,
            },
            vatRate: 21,
            vatRates: [
                {
                    rate: 0,
                    text: 'No VAT',
                },
                {
                    rate: 21,
                    text: 'Dutch VAT',
                },
            ],
            vatNumber: '',
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'tiers',
            'user',
        ]),
    },
    

    methods: {
        ...mapActions([
            'getClients',
        ]),


        async addUser()
        {
            vm.$emit('processing', true);

            try {
                let formData = new FormData(vm.$refs.form);

                // Append the chosen VAT rate
                formData.append('vat_rate', vm.vatRate);
                
                // Only add the VAT number if a number is entered
                if (vm.vatNumber.length > 0) formData.append('vat_number', vm.vatNumber);
                
                const response = await axios.post('api/user/' + vm.user.id + '/create', formData);

                if (response.data === false)
                {
                    vm.$emit('processing', false);
                    
                    alert('Failed to create a new user.\nPerhaps the email address already exists?');
                }
                else
                {
                    // Reset the form
                    vm.client = JSON.parse(JSON.stringify(vm.default));
                    vm.vatNumber = '';

                    // Get the new list of clients
                    vm.getClients().then(() => {
                        vm.$emit('processing', false);
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>