<template>
    <footer>
        <span>
            &copy; {{ year }} {{ siteName }}
        </span>

        <a href="https://artists.pascalmaranus.com/docs/pm-terms-and-conditions.pdf">Terms & Conditions</a>
    </footer>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'siteName',
        ]),


        year()
        {
            let date = new Date();
    
            return date.getFullYear();
        },
    },
}
</script>