<template>
    <h2>
        Current selection
    </h2>
    <ul class="items" v-if="images !== null">
        <li class="product" v-for="item in content" :key="item.id">
            <img :src="base + item.preview" :alt="item.id" />

            <strong v-html="item.id" />

            <span>
                {{ asMoney(item.price) }}
            </span>
        </li>

        <li class="summary" v-if="subtotal != total">
            <strong>
                Subtotal:
            </strong>

            <span>
                {{ asMoney(subtotal) }}
            </span>
        </li>

        <li class="summary" v-if="fees > 0">
            <strong>
                License costs:
            </strong>

            <span>
                {{ asMoney(fees) }}
            </span>
        </li>

        <li class="summary" v-if="discount > 0">
            <strong>
                Discount:
            </strong>

            <span>
                {{ asMoney(discount) }}
            </span>
        </li>

        <li class="summary">
            <strong>
                Total:
            </strong>

            <span>
                {{ asMoney(total) }}
            </span>
        </li>

        <li>
            <a href="#" class="button add-to-cart" @click.prevent="addOrder" :disabled="processing">
                <span v-if="!processing">
                    Checkout
                </span>
                
                <i v-else class="fa-solid fa-spinner fa-spin"></i>
            </a>
        </li>
    </ul>
</template>


<script>
import { mapGetters } from 'vuex';

import axios from 'axios';

import useCart from '@/hooks/cart.js';

var vm;

export default {
    data()
    {
        return {
            processing: false,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'cart',
            'images',
            'moneyFormat',
            'orders',
            'user',
            'userTier',
        ]),

        
        content()
        {
            let c = [];

            for (let i = 0; i < vm.images.length; i++)
            {
                let img = vm.images[i];

                if (vm.cart.indexOf(img.id) >= 0)
                {
                    c.push(img);
                }
            }

            return c;
        },


        discount()
        {
            for (let i = vm.orders.length - 1; i >= 0; i--)
            {
                let o = vm.orders[i];

                // Return 10% of the latest paid order, if there is one
                if (o.status === 'paid')
                {
                    let prevOrderDiscount = parseFloat(o.costs) * 0.1;
                    
                    return vm.calcDiscount(vm.content, vm.userTier, prevOrderDiscount);
                }
            }

            return 0;
        },
        

        fees()
        {
            return vm.cart.length * parseFloat(vm.userTier.license_fee);
        },
        

        subtotal()
        {
            let total = 0;

            for (let i = 0; i < vm.content.length; i++)
            {
                let item = vm.content[i];

                total += parseFloat(item.price);
            }

            return total;
        },


        total()
        {
            return vm.subtotal + vm.fees - vm.discount;
        },
    },


    methods: {
        async addOrder()
        {
            vm.processing = true;

            try {
                let formData = new FormData();
                
                formData.append('fk_user', vm.user.id);
                formData.append('costs', vm.total);
                formData.append('items', vm.cart);
                // formData.append('redirect', window.location.href);
                formData.append('redirect', vm.base + 'images');

                const response = await axios.post('api/order/create', formData);

                vm.processing = false;

                if (response.data === false)
                {
                    alert('Failed to create a new order');
                }
                else
                {
                    // Redirect to the checkout page
                    // console.log(response.data);
                    window.open(response.data, '_self');
                }
            } catch (error) {
                console.log(error);
            }
        },


        
    },


	setup()
	{
		const { asMoney, calcDiscount } = useCart();


		return {
            asMoney,
            calcDiscount,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>