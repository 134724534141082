import { createRouter, createWebHistory } from "vue-router";

import Clients from "@/views/ClientsPage.vue";
import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/HomePage.vue";
import Images from "@/views/ImagesPage.vue";
import Orders from "@/views/OrdersPage.vue";
import Request from "@/views/RequestPage.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: Home,
    },

    {
        path: "/clients",
        name: "ClientsPage",
        component: Clients,
    },

    {
        path: "/images",
        name: "ImagesPage",
        component: Images,
    },

    {
        path: "/orders",
        name: "OrdersPage",
        component: Orders,
    },

    {
        path: "/request",
        name: "RequestPage",
        component: Request,
    },

    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return savedPosition ? savedPosition : { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
