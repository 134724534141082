<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section class="wide">
                    <h1>
                        Reference images
                    </h1>

                    <image-form v-if="user.hasOwnProperty('fk_tier') && user.fk_tier == 1"
                        @processing="setProcessing" />


                    <p>
                        Select your images to purchase below. Whenever you're ready, click on the

                        <a href="#" @click.prevent="showCart">cart button</a>

                        in the menu to proceed to checkout.
                    </p>


                    <form class="filters">
                        <h3>
                            Refine your search
                        </h3>

                        <ul>
                            <li>
                                <label for="to-show">
                                    Images:
                                </label>

                                <select v-model="toShow" id="to-show">
                                    <option v-for="o in showOptions" :key="o.value" :value="o.value">
                                        {{ o.text }}
                                    </option>
                                </select>
                            </li>
                            <li>
                                <label for="order">
                                    Order:
                                </label>

                                <select v-model="orderBy" id="order">
                                    <option v-for="o in orderOptions" :key="o.value" :value="o.value">
                                        {{ o.text }}
                                    </option>
                                </select>
                            </li>
                        </ul>
                    </form>


                    <div class="images">
                        <figure v-for="img in availableImages" :class="imageClasses(img)" :key="img.id"
                            :data-id="img.id">
                            <img :src="getImageUrl(img)" :alt="img.id" />

                            <div class="info">
                                <ul>
                                    <li>
                                        <strong>
                                            {{ img.id }}
                                        </strong>
                                    </li>

                                    <li>
                                        <small>
                                            Costs (incl. license)
                                        </small>

                                        <strong>
                                            {{ getCosts(img.price) }} {{ currency }}
                                        </strong>
                                    </li>

                                    <li>
                                        <button v-if="!isDownloadable(img.id)"
                                            @click.prevent="toggleImageSelection(img.id)">
                                            <span v-if="isSelected(img.id)">
                                                Remove from cart
                                            </span>
                                            <span v-else>
                                                Add to cart
                                            </span>
                                        </button>
                                        <button v-else @click.prevent="download(img)">
                                            Download
                                        </button>
                                    </li>

                                    <li v-if="isOwned(img.id)">
                                        <button @click.prevent="updateImage(img)" class="secondary">
                                            Update image
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <figcaption>
                                <span>
                                    {{ img.id }}
                                </span>

                                <span>
                                    <a href="#" v-if="isOwned(img.id) && img.deleted == 0"
                                        @click.prevent="deleteImage(img.id)">
                                        <i class="fas fa-trash-can"></i>
                                    </a>

                                    <a href="#" v-if="isOwned(img.id) && img.deleted == 1"
                                        @click.prevent="recoverImage(img.id)">
                                        <i class="fas fa-trash-can-slash"></i>
                                    </a>
                                </span>

                                <span>
                                    <a href="#" v-if="isDownloadable(img.id)" @click.prevent="download(img)">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

import ImageForm from '@/components/ImageForm';
import scrollFX from '@/mixins/scrollFX';

var vm;

export default {
    components: {
        ImageForm,
    },


    mixins: [
        scrollFX,
    ],


    data()
    {
        return {
            orderBy: 'newest',
            orderOptions: [
                {
                    value: 'id',
                    text: 'Name A-Z',
                },
                {
                    value: 'price_asc',
                    text: 'Price low-high',
                },
                {
                    value: 'price_desc',
                    text: 'Price high-low',
                },
                {
                    value: 'newest',
                    text: 'New - old',
                },
                {
                    value: 'oldest',
                    text: 'Old - new',
                },
            ],
            showOptions: [
                {
                    value: 'all',
                    text: 'All images',
                },
                {
                    value: 'owned',
                    text: 'My purchased images',
                },
                {
                    value: 'new',
                    text: 'Other available images',
                },
            ],
            selection: null,
            toShow: 'all',
        }
    },

    
    computed: {
        ...mapGetters([
            'base',
            'cart',
            'currency',
            'images',
            'isDev',
            'tiers',
            'user',
        ]),


        availableImages()
        {
            let imgs = [],
                keys = [],
                keyMap = {},
                results = [];
            
            for (let i = 0; i < vm.images.length; i++)
            {
                let img = vm.images[i],
                    key = img.id;
                
                // Skip deleted one, unless the user's bought them before or owns it
                if (img.deleted == 1 && !vm.isDownloadable(img.id)) continue;

                // Filter the unwanted images in advance
                if (vm.toShow == 'new' && vm.isDownloadable(img.id)) continue;
                if (vm.toShow == 'owned' && !vm.isDownloadable(img.id)) continue;

                // Determine the sort key
                switch (vm.orderBy)
                {
                    case 'price_asc':
                    case 'price_desc':
                        key = img.price.replace('.', '') + img.id;
                        break;

                    case 'newest':
                    case 'oldest':
                        key = img.updated.replace(/-|:|\s/g, '') + img.id;
                        break;
                }

                // Store the necessary data
                keys.push(key);
                keyMap[key] = img;
                imgs.push(img);
            }

            // Sort the keys
            keys.sort();

            // If needed, reverse the order
            if (['price_desc', 'newest'].indexOf(vm.orderBy) >= 0)
            {
                keys.reverse();
            }

            // Loop through the ordered keys
            for (let i = 0; i < keys.length; i++)
            {
                results.push(keyMap[keys[i]]);
            }

            return results;
        },


        licenseFee()
        {
            for (let i = 0; i < vm.tiers.length; i++)
            {
                let t = vm.tiers[i];

                if (t.id == vm.user.fk_tier) return t.license_fee;
            }
            
            return -1;
        },
    },


    methods: {
        ...mapActions([
            'updateCart',
            'updateImages',
            'updateUser',
        ]),


        async deleteImage(id)
        {
            vm.$emit('processing', true);

            try {
                const response = await axios.post('api/image/' + id + '/delete');

                // Update the images and the user objects
                vm.updateImages(response.data.images);
                vm.updateUser(response.data.user);
                
                vm.$emit('processing', false);
            } catch (error) {
                console.log(error);
            }
        },


        async recoverImage(id)
        {
            vm.$emit('processing', true);

            try {
                const response = await axios.post('api/image/' + id + '/recover');

                // Update the images and the user objects
                vm.updateImages(response.data.images);
                vm.updateUser(response.data.user);
                
                vm.$emit('processing', false);
            } catch (error) {
                console.log(error);
            }
        },


        download(img)
        {
            // Image download
            let url = vm.isDev ? vm.base : '';
            url += 'download/' + img.id;
            window.open(url, '_blank').focus();
        },


        getCosts(price)
        {
            return (parseFloat(vm.licenseFee) + parseFloat(price)).toFixed(2);
        },


        getImageUrl(img)
        {
            let base = vm.isDev ? vm.base : '/',
                version = img.updated,
                url = base + img.preview;

                
            if (version)
            {
                let d = new Date(version.replace(' ', 'T'));
                url += '?v=' + d.valueOf();
            }

            return url;
        },


        imageClasses(img)
        {
            let cl = [
                'image',
            ];

            if (img.deleted == 1) cl.push('deleted')
            if (vm.isSelected(img.id)) cl.push('selected')

            return cl.join(' ')
        },


        isDownloadable(id)
        {
            // Check if the image is owned by the user
            for (let i = 0; i < vm.user.images.owned.length; i++)
            {
                let img = vm.user.images.owned[i];
                
                if (img == id) return true;
            }

            // Check if the image is purchased by the user
            for (let i = 0; i < vm.user.images.purchased.length; i++)
            {
                let img = vm.user.images.purchased[i];
                
                if (img == id) return true;
            }

            return false;
        },


        isOwned(id)
        {
            for (let i = 0; i < vm.user.images.owned.length; i++)
            {
                let img = vm.user.images.owned[i];

                if (img == id) return true;
            }

            return false;
        },


        isSelected(id)
        {
            return vm.selection.indexOf(id) >= 0;
        },


        setProcessing(value)
        {
            vm.$emit('processing', value);
        },


        showCart()
        {
            if (vm.cart.length > 0)
            {
                vm.$emit('showCart');
            }
        },


        toggleImageSelection(id)
        {
            // Get the position in the active selection
            let pos = vm.selection.indexOf(id);

            // If the image is selected, remove it
            if (pos >= 0) vm.selection.splice(pos, 1);
            else vm.selection.push(id)
        },


        updateImage(img)
        {
            vm.$emit('updateImage', img);
        },
    },


    created()
    {
        vm = this;

        // Set the stored cart data
        vm.selection = vm.cart;
    },


    watch: {
        selection: {
            handler(to, from)
            {
                if (from !== null)
                {
                    // If the cart is updated, send that to the backend as well
                    vm.updateCart(to);
                }
            },
            deep: true,
        },
    }
}
</script>