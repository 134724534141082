import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';
// import { resolve } from "core-js/es6/promise";

var base = 'https://artists.pascalmaranus.com/';

export default createStore({
    state: {
        base: base,
        cart: [],
        clients: null,
        currency: 'EUR',
        freeBreakpoint: 4,
        images: [],
        isDev: process.env.NODE_ENV == 'development',
        moneyFormat: new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'code',
        }),
        orders: [],
        sales: [],
        siteName: 'Pascal Maranus',
        structure: [
            {
                icon: 'fa-solid fa-user',
                name: 'Home',
                route: {
                    name: 'HomePage',
                },
            },
            {
                icon: 'fa-solid fa-images',
                name: 'Images',
                route: {
                    name: 'ImagesPage',
                },
            },
            {
                icon: 'fa-solid fa-comment-question',
                name: 'Requests',
                route: {
                    name: 'RequestPage',
                },
            },
            {
                icon: 'fa-solid fa-list',
                name: 'Orders',
                route: {
                    name: 'OrdersPage',
                },
            }
        ],
        tiers: [],
        user: null,
    },


    getters: {
        base: state => {
            return state.base;
        },


        cart: state => {
            return state.cart;
        },


        clients: state => {
            return state.clients;
        },


        currency: state => {
            return state.currency;
        },


        discountMessage: state => {
            if (state.cart.length === 2)
            {
                return 'Add 1 more image to unlock a <strong>discount</strong>';
            }

            let _fromFree = (state.cart.length / state.freeBreakpoint),
                fromFree = Math.round((Math.ceil(_fromFree) - _fromFree) * state.freeBreakpoint);

            if (fromFree === 1)
            {
                let free = Math.ceil(_fromFree);

                return `Add 1 more image to get <strong>${free} for free</strong>`;
            }

            return false;
        },

        
        freeBreakpoint: state => {
            return state.freeBreakpoint;
        },


        images: state => {
            return state.images;
        },


        isAdmin: state => {
            return state.user.fk_tier == 1;
        },


        isDev: state => {
            return state.isDev;
        },


        isLoggedIn: state => {
            return typeof (state.user) != 'undefined' && state.user !== null && state.user.id != 0;
        },


        moneyFormat: state => {
            return state.moneyFormat;
        },


        orders: state => {
            return state.orders;
        },


        sales: state => {
            return state.sales;
        },


        siteName: state => {
            return state.siteName;
        },


        sizes: state => {
            return state.sizes;
        },


        structure: state => {
            return state.structure;
        },


        tiers: state => {
            return state.tiers;
        },


        user: state => {
            return state.user;
        },


        userTier: state => {
            for (let i = 0; i < state.tiers.length; i++)
            {
                if (state.user.fk_tier == state.tiers[i].id) return state.tiers[i];
            }

            return null;
        },
    },


    mutations: {
        setCart(state, value) {
            state.cart = value;
        },

        setClients(state, value) {
            state.clients = value;
        },

        setImages(state, value) {
            state.images = value;
        },

        setOrders(state, value) {
            state.orders = value;
        },

        setSales(state, value) {
            state.sales = value;
        },

        setTiers(state, value) {
            state.tiers = value;
        },

        setUser(state, value) {
            state.user = value;
        },
    },


    actions: {
        _initialize: async ({ commit }, payload) => {
            try {
                let response;

                if (payload)
                {
                    response = await axios.post('api/user/login', payload);
                }
                else
                {
                    response = await axios.get('api/', { params: payload });
                }

                if (response.data)
                {
                    commit('setUser', response.data.user);
    
                    if (Object.prototype.hasOwnProperty.call(response.data, 'cart')) {
                        commit('setCart', response.data.cart);
                    }
                    if (Object.prototype.hasOwnProperty.call(response.data, 'images')) {
                        commit('setImages', response.data.images);
                    }
                    if (Object.prototype.hasOwnProperty.call(response.data, 'orders')) {
                        commit('setOrders', response.data.orders);
                    }
                    if (Object.prototype.hasOwnProperty.call(response.data, 'tiers')) {
                        commit('setTiers', response.data.tiers);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },


        getClients: async ({ state, commit }) => {
            try {
                const response = await axios.get('api/user/' + state.user.id + '/clients');

                commit('setClients', response.data);
            } catch (error) {
                console.log(error);
            }
        },


        getImages: async ({ commit }) => {
            try {
                const response = await axios.get('api/image/all');

                commit('setImages', response.data);
            } catch (error) {
                console.log(error);
            }
        },


        getSales: async ({ state, commit }) => {
            try {
                const response = await axios.get('api/user/' + state.user.id + '/sales');

                commit('setSales', response.data);
            } catch (error) {
                console.log(error);
            }
        },


        login: async ({ commit }, payload) => {
            try {
                const response = await axios.post('api/user/login', payload.data);

                commit('setCart', response.data.cart);
                commit('setOrders', response.data.orders);
                commit('setTiers', response.data.tiers);
                commit('setUser', response.data.user);

                // Store the user locally, if wanted and the login was successful
                if (payload.store && response.data.user)
                {
                    localStorage.setItem('axpmUser', btoa(JSON.stringify(payload.data)));
                }

                if (Object.prototype.hasOwnProperty.call(response.data, 'images')) {
                    commit('setImages', response.data.images);
                }
            } catch (error) {
                console.log(error);
            }
        },


        logout: async ({ commit }) => {
            try {
                const response = await axios.post('api/user/logout');

                commit('setUser', response.data.user);
                commit('setOrders', response.data.orders);

                localStorage.removeItem('axpmUser');

                if (Object.prototype.hasOwnProperty.call(response.data, 'images')) {
                    commit('setImages', response.data.images);
                }
            } catch (error) {
                console.log(error);
            }
        },


        updateCart: async ({ commit }, payload) => {
            try {
                const response = await axios.post('api/cart/update', {
                    items: payload,
                });

                commit('setCart', response.data);
            } catch (error) {
                console.log(error);
            }
        },


        updateClients: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('setClients', payload);
                resolve();
            });
        },


        updateImages: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('setImages', payload);
                resolve();
            });
        },


        updateUser: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('setUser', payload);
                resolve();
            });
        },
    },
});
