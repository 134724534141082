<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section class="split">
                    <h1>
                        Request a photo
                    </h1>
                    
                    <div>
                        <p>
                            If you have seen a photo on Instagram that's not listed here, or you need something
                            for a commission that you can't find... You can always leave a request.
                        </p>
    
                        <p>
                            When requesting a photo you've seen on Instagram, be sure to mention the animal(s) and the date
                            it was posted, so there's no confusion about which image it is we're talking about.
                        </p>

                        <p>
                            Of course, you can also simply share the post via DM with
                            <a href="https://www.instagram.com/pascalmaranus" target="_blank">@pascalmaranus</a> on IG.
                        </p>
                    </div>                    


                    <contact-form />
                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import scrollFX from '@/mixins/scrollFX';

import ContactForm from '@/components/ContactForm';

export default {
    components: {
        ContactForm,
    },


    mixins: [
        scrollFX,
    ],
}
</script>