import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useFunctions() {
    const store = useStore();

    const freeBreakpoint = computed(() => store.state.freeBreakpoint);
    const moneyFormat = computed(() => store.state.moneyFormat);
    
    
    function asMoney(input)
    {
        return moneyFormat.value.format(input);
    }


    function calcDiscount(items, tier, discountBase)
    {
        let d = discountBase,
            fee = parseFloat(tier.license_fee),
            prices = [];

        // Get all the prices of the images
        for (let i = 0; i < items.length; i++) {
            let img = items[i],
                imgPrice = fee + parseFloat(img.price);

            prices.push(imgPrice);
        }

        // Sort them alphabetically
        prices.sort();

        // Determine the amount of free images
        let free = Math.floor(prices.length / freeBreakpoint.value);

        // Add the prices of the free images to the discount
        for (let j = 0; j < free; j++) {
            d += prices[j];
        }

        // If there's any spare images that have earned a discount, calculate that here
        let spareDiscount = 0,
            spareImages = prices.length - (free * freeBreakpoint.value);

        if (prices.length < 3) spareImages = 0;

        if (tier) {
            switch (tier.id) {
                case 3:
                    spareDiscount = 20;
                    break;

                case 4:
                    spareDiscount = 35;
                    break;

                case 5:
                    spareDiscount = 65;
                    break;

                case 6:
                    spareDiscount = 100;
                    break;

                case 7:
                    spareDiscount = 150;
                    break;

                default:
                    spareDiscount = 5;
                    break;
            }

            d += (spareDiscount * spareImages);
        }

        return d;
    }


    return {
        asMoney,
        calcDiscount,
    };
}