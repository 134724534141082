<template>
    <form class="contact-form blend-in" ref="form">
        <h2>
            Leave your request
        </h2>

        <ul class="unstyled-list">
            <li>
                <textarea name="message" placeholder="Your request..." v-model="message" />
            </li>
            <li>
                <button :class="isDisabled ? 'disabled' : ''" @click.prevent="sendRequest">
                    Send your request
                </button>
            </li>
        </ul>
    </form>
</template>


<script>
import { mapGetters } from 'vuex';

import axios from 'axios';

var vm;

export default {
    data()
    {
        return {
            message: '',
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'user',
        ]),


        isDisabled()
        {
            return vm.message.length === 0;
        },
    },
    

    methods: {
        async sendRequest()
        {
            vm.$emit('processing', true);

            try {
                let formData = new FormData(vm.$refs.form);

                const response = await axios.post('api/user/' + vm.user.id + '/request', formData);

                if (response.data === false)
                {
                    vm.$emit('processing', false);
                    
                    alert('We were unable to send your request at this time.\nFeel free to try again. Or email at artists@pascalmaranus.com.');
                }
                else
                {
                    // Reset the form
                    vm.message = '';
                }
            } catch (error) {
                console.log(error);
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>