<template>
    <form class="blend-in">
        <h1>
            Log in
        </h1>

        <ul>
            <li>
                <input type="email" placeholder="Email..." v-model="form.email" />
            </li>
            <li>
                <input :type="pwType" placeholder="Password..." v-model="form.password" />
                <a href="#" class="toggle-pw" @click.prevent="showPw = !showPw">
                    <i class="fas" :class="pwIcon"></i>
                </a>
            </li>
            <li>
                <button @click.prevent="loginUser">
                    Log in
                </button>

                <small>
                    <input type="checkbox" v-model="storeLocally" id="local">
                    <label for="local">
                        Keep me logged in on this device.
                    </label>
                </small>
            </li>
        </ul>
    </form>
</template>


<script>
import { mapActions } from 'vuex';

var vm;

export default {
    data()
    {
        return {
            form: {
                email: '',
                password: '',
            },
            showPw: false,
            storeLocally: false,
        }
    },


    computed: {
        pwIcon()
        {
            return vm.showPw ? 'fa-eye-slash' : 'fa-eye';
        },


        pwType()
        {
            return vm.showPw ? 'text' : 'password';
        },
    },


    methods: {
        ...mapActions([
            'getImages',
            'login',
        ]),


        loginUser()
        {
            vm.login({ data: vm.form, store: vm.storeLocally })
            .then()
            {                
                vm.getImages()
                vm.$emit('loggedin')
            }
        },
    },


    created()
    {
        vm = this;
    },
}
</script>