<template>
    <div id="smooth-wrapper">
        <div id="smooth-content">

            <main>
                <section class="sidebar">
                    <aside v-if="isAdmin">
                        <order-form @processing="process" />
                    </aside>

                    <div class="main">
                        <div v-if="isAdmin">
                            <h2>
                                My sales
                            </h2>

                            <ul class="orders">
                                <li v-if="sales.length === 0">
                                    <span class="full">
                                        You have yet to make your first sale here.
                                    </span>
                                </li>
                                <li v-else class="columns">
                                    <strong>
                                        Order
                                    </strong>

                                    <strong data-column="status">
                                        Status
                                    </strong>
                                </li>

                                <li v-for="o in salesList" :key="o.id">
                                    <span class="info">
                                        <strong>
                                            #{{ o.ordernumber }}
                                        </strong>

                                        <small>
                                            {{ getBuyer(o) }}
                                        </small>

                                        <small>
                                            {{ formateDate(o.date) }}
                                        </small>

                                        <small>
                                            {{ asMoney(o.costs) }}
                                        </small>
                                    </span>


                                    <span data-column="status">
                                        <i v-if="o.status == 'paid'" class="fa-solid fa-check"></i>
                                        <i v-else-if="o.status == 'cancelled'" class="fa-solid fa-ban"></i>
                                        <i v-else class="fa-solid fa-timer"></i>

                                        <small>
                                            {{ o.status == 'open' ? 'not paid' : o.status }}
                                        </small>
                                    </span>

                                    <div class="items">
                                        <img v-for="item in o.items" :key="item.id"
                                            :src="getImageById(item.fk_image, 'preview')" />
                                    </div>
                                </li>
                            </ul>

                            <a v-if="sales.length > limit" href="#" @click.prevent="allSales = !allSales">
                                {{ showHideSales }}
                            </a>
                        </div>

                        <div>
                            <h2>
                                My orders
                            </h2>

                            <ul class="orders">
                                <li v-if="orders.length === 0">
                                    <span class="full">
                                        You have not purchased anything yet.
                                    </span>
                                </li>
                                <li v-else class="columns">
                                    <strong>
                                        Order
                                    </strong>

                                    <strong data-column="status">
                                        Status
                                    </strong>
                                </li>

                                <li v-for="o in ordersList" :key="o.id">
                                    <span class="info">
                                        <strong>
                                            #{{ o.ordernumber }}
                                        </strong>

                                        <small>
                                            {{ formateDate(o.date) }}
                                        </small>

                                        <small>
                                            {{ asMoney(o.costs) }}
                                        </small>
                                    </span>

                                    <span data-column="status">
                                        <i v-if="o.status == 'paid'" class="fa-solid fa-check"></i>
                                        <i v-else-if="o.status == 'open'" class="fa-solid fa-timer"></i>
                                        <i v-else class="fa-solid fa-ban"></i>

                                        <small>
                                            {{ o.status == 'open' ? 'not paid' : o.status }}
                                        </small>

                                        <small v-if="o.status != 'paid' && o.status != 'cancelled'">
                                            <a href=" #" class="paylink" @click.prevent="checkout(o)">
                                                Pay now
                                            </a>
                                        </small>
                                    </span>

                                    <div class="items">
                                        <img v-for="item in o.items" :key="item.id"
                                            :src="getImageById(item.fk_image, 'preview')" />
                                    </div>

                                    <div class="downloads">
                                        <strong>
                                            Your files:
                                        </strong>

                                        <a href="#" @click.prevent="downloadOrder(o.id)" title="Download the images, license and invoice">
                                            <i class="fa-solid fa-file-zip"></i>
                                        </a>

                                        <a href="#" @click.prevent="downloadLicense(o.id)" title="Download the license of this order">
                                            <i class="fa-solid fa-file-lines"></i>
                                        </a>

                                        <a href="#" @click.prevent="downloadInvoice(o.id)" title="Download the invoice of this order">
                                            <i class="fa-solid fa-file-invoice-dollar"></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <a v-if="orders.length > limit" href="#" @click.prevent="allOrders = !allOrders">
                                {{ showHideOrders }}
                            </a>
                        </div>
                    </div>
                </section>
            </main>


            <slot name="footer" />

        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

import scrollFX from '@/mixins/scrollFX';
import useCart from '@/hooks/cart.js';

import OrderForm from '@/components/OrderForm';

var vm;

export default {
    components: {
        OrderForm,
    },


    mixins: [
        scrollFX,
    ],


    data()
    {
        return {
            allOrders: false,
            allSales: false,
            limit: 5,
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'clients',
            'images',
            'isAdmin',
            'orders',
            'sales',
            'user',
        ]),


        ordersList()
        {
            return vm.reverseAndLimitArray(vm.orders, vm.allOrders);
        },


        salesList()
        {
            return vm.reverseAndLimitArray(vm.sales, vm.allSales);
        },


        showHideOrders()
        {
            return vm.allOrders ? 'Show only the latest orders' : 'Show all orders'
        },


        showHideSales()
        {
            return vm.allSales ? 'Show only the latest sales' : 'Show all sales'
        },
    },


    methods: {
        ...mapActions([
            'getClients',
            'getSales',
        ]),


        async checkout(o) {
            vm.$emit('processing', true);

            try {
                let formData = new FormData();
                formData.append('redirect', window.location.href);
                const response = await axios.post('api/order/' + o.id + '/checkout', formData);

                if (response.data === false) {
                    alert('There was an issue creating your payment link.');
                }
                else if (response.data !== null) {
                    // Redirect to the checkout page
                    window.open(response.data, '_self');
                }

                vm.$emit('processing', false);
            } catch (error) {
                console.log(error);
            }
        },


        download(id, type)
        {
            let url = vm.base + ['pdf', type, `${vm.user.id}x${id}`].join('/');
            
            window.open(url, '_blank');
        },


        downloadInvoice(id)
        {
            vm.download(id, 'invoice');
        },


        downloadLicense(id)
        {
            vm.download(id, 'license');
        },


        downloadOrder(id)
        {
            let url = vm.base + 'download/' + id;

            window.open(url, '_blank');
        },


        formateDate(input)
        {
            let ts = input.replace(' ', 'T'),
                date = new Date(ts);

            return [
                date.toLocaleDateString('en-GB'),
                // date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }),
                // date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0'),
            ].join(' - ');
        },


        getBuyer(o)
        {
            for (let i = 0; i < vm.clients.length; i++)
            {
                let cl = vm.clients[i];

                if (cl.id == o.fk_user) return cl.name;
            }

            return '-';
        },


        getImageById(id, field)
        {
            for (let i = 0; i < vm.images.length; i++)
            {
                let img = vm.images[i];

                if (img.id == id)
                {
                    if (field)
                    {
                        let res = img[field];

                        if (field === 'preview' || field === 'full') res = vm.base + res;

                        return res;
                    }
                    else
                    {
                        return img;
                    }
                }
            }

            return null;
        },


        process(value) {
            vm.$emit('processing', value);
        },


        reverseAndLimitArray(array, all)
        {
            // Make clone the array, so the original won't be affected
            let copy = JSON.parse(JSON.stringify(array));
            copy.reverse();

            return all ? copy : copy.slice(0, vm.limit);
        },
    },


	setup()
	{
		const { asMoney } = useCart();


		return {
            asMoney,
		};
	},


    created() {
        vm = this;

        if (vm.isAdmin)
        {
            vm.getClients()
            .then(() => {
                vm.getSales();
            });
        }
    },
}
</script>